<template>
    <item-list-view
        :api="api"
        :headers="headers"
        items-key="items"
        id-key="id"
        title="Клиники"
        draggable
        :allowed-search="false"
        :allowed-create="false"
        :allowed-remove="false"
        update-route="Clinic"
        :create-route="{ name: 'Clinic', params: { id: 'new' }}"
        remove-title="Удаление клиники"
        remove-message="Вы действительно хотите удалить клинику '<%= item.name %>'?"
        @[ON_REORDER]="onReorder"
    >
      <template v-slot:right-after>
        <v-btn color="success" @click="saveOrder" :disabled="!reordered">
          <v-icon small left>fa-save</v-icon>
          Сохранить
        </v-btn>
      </template>
        <template v-slot:item.private="{ item }">
            <v-chip small label dark :color="item.private ? 'red' : 'green'">
                {{ item.private ? 'Да' : 'Нет' }}
            </v-chip>
        </template>
        <template v-slot:item.draggable>
        <v-btn icon small>
          <v-icon>mdi-drag</v-icon>
        </v-btn>
      </template>
    </item-list-view>
</template>

<script>
import api from '../../services/clinics'
import ItemListView, { ON_REORDER } from '../../components/ItemListView'

export default {
  components: { ItemListView },
  data: () => ({
    ON_REORDER,
    api: api,
    headers: [
      { text: '', value: 'draggable' },
      { text: 'Название', value: 'name' },
      { text: 'Uri', value: 'uri' },
      { text: 'Скрыто', value: 'private' },
      { text: '', value: 'action' }
    ],
    reordered: null
  }),
  methods: {
    onReorder (list) {
      this.reordered = list.map(({ id }) => id)
    },
    saveOrder () {
      return api.order(this.reordered)
    }
  }
}
</script>
